import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { NotificationResponse } from '../models/Notification';
import NotificationService from '../services/NotificationService';
import { useTranslation } from 'react-i18next';
import { useCurrentClient as useCurrentClientState } from '../global-state/Clients';

type Props = {
  enableReadNotifications?: boolean;
  useCurrentClient?: boolean;
};

type NotificationsAccessor = {
  notifications: NotificationResponse[];
  hasMore: boolean;
  loadMore: () => void;
  total: number;
  loading: boolean;
  isFetching: boolean;
  refresh: () => void;
};

export type UseNotificationsData = {
  markAllAsRead: () => void;
  unread: NotificationsAccessor & {
    markRead: (id: string | string[]) => void;
  };
  read: NotificationsAccessor & {
    markUnRead: (id: string | string[]) => void;
  };
};

export const useNotifications = (props?: Props): UseNotificationsData => {
  const { enableReadNotifications, useCurrentClient } = props || {};
  const { i18n } = useTranslation();
  const currentClient = useCurrentClientState((x) => x.value);

  const {
    data: unreadData,
    isFetching: unreadLoading,
    refetch: refreshUnread,
    hasNextPage: unreadHasMore,
    fetchNextPage: loadMoreUnread,
    isFetchingNextPage: unreadIsFetchingNextPage,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      NotificationService.getNotifications({
        isRead: false,
        pageNumber: pageParam,
        pageSize: 25,
        clientId: currentClient?.id,
      }),
    queryKey: ['unread-notifs', i18n.language, currentClient?.id],
    refetchOnWindowFocus: false,
    getNextPageParam(lastPage) {
      return lastPage.hasNextPage ? (lastPage.pageNumber ?? 0) + 1 : undefined;
    },
    enabled: useCurrentClient ? !!currentClient?.id : true,
  });

  const unread = useMemo(() => unreadData?.pages.flatMap((x) => x.data ?? []) ?? [], [unreadData?.pages]);
  const unreadTotal = useMemo(() => unreadData?.pages?.[0].totalCount ?? 0, [unreadData?.pages]);

  const {
    data: readData,
    isFetching: readLoading,
    refetch: refreshRead,
    hasNextPage: readHasMore,
    fetchNextPage: loadMoreRead,
    isFetchingNextPage: readIsFetchingNextPage,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['read-notifs', i18n.language, currentClient?.id],
    queryFn: ({ pageParam = 1 }) =>
      NotificationService.getNotifications({
        isRead: true,
        pageNumber: pageParam,
        pageSize: 25,
        clientId: currentClient?.id,
      }),
    refetchOnWindowFocus: false,
    getNextPageParam(lastPage) {
      return lastPage.hasNextPage ? (lastPage.pageNumber ?? 0) + 1 : undefined;
    },
    enabled: enableReadNotifications && (useCurrentClient ? !!currentClient?.id : true),
  });

  const read = useMemo(() => readData?.pages.flatMap((x) => x.data ?? []) ?? [], [readData?.pages]);
  const readTotal = useMemo(() => readData?.pages?.[0].totalCount ?? 0, [readData?.pages]);

  const markAsRead = useCallback(
    (id: string | string[]) => {
      const ids = Array.isArray(id) ? id : [id];
      NotificationService.updateNotification({ ids, markAsRead: true }).then(() => {
        refreshUnread();
      });
    },
    [refreshUnread],
  );

  const markAllAsRead = useCallback(() => {
    NotificationService.markAllAsRead(currentClient?.id).then(() => {
      refreshUnread();
    });
  }, [currentClient?.id, refreshUnread]);

  const markAsUnRead = useCallback(
    (id: string | string[]) => {
      const ids = Array.isArray(id) ? id : [id];
      NotificationService.updateNotification({ ids, markAsRead: false }).then(() => {
        refreshRead();
      });
    },
    [refreshRead],
  );

  return {
    markAllAsRead,
    unread: {
      notifications: unread,
      hasMore: !!unreadHasMore,
      loadMore: loadMoreUnread,
      total: unreadTotal,
      loading: unreadLoading && !unreadIsFetchingNextPage,
      isFetching: unreadLoading,
      markRead: markAsRead,
      refresh: refreshUnread,
    },
    read: {
      notifications: read,
      hasMore: !!readHasMore,
      loadMore: loadMoreRead,
      total: readTotal,
      loading: readLoading && !readIsFetchingNextPage,
      isFetching: readLoading,
      refresh: refreshRead,
      markUnRead: markAsUnRead,
    },
  };
};
